import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid2 as Grid,
  Skeleton,
  SxProps,
  ToggleButton,
  Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useUiState } from '@utils/UiStateHook';
import DisabledCardState from '@components//Cards/DisabledCardState';
import { ToDate } from '@utils/DateUtils';
import { DateTime } from 'luxon';
import { GoalStatus } from '@app/enums/GoalStatus';
import { Circle, CircleOutlined } from '@mui/icons-material';
import { ConfirmGoalCompletionDialog, GoalWithStatus, UpdateGoalsDialog } from '@components';
import { useIsFirstWeek } from '@app/hooks/useIsFirstWeek';
import { useGoalCardViewCreator } from '@viewCreators/GoalCardViewCreator';
import { ExerciseHistory } from '@components/modals';
import { useJailCardModalController } from '@components/GoalTracking/useJailCardModalController';
import { useConfirmGoalCompletionDialogController } from '@components/GoalTracking/useConfirmGoalCompletionDialogController';
import { JailCardStatusDialog } from '@components/GoalTracking/jail-card-status-dialog';
import { useUpdateGoalsDialogController } from '@components/GoalTracking/useUpdateGoalsDialogController';
import { useExerciseHistoryController } from '@components/GoalTracking/useExerciseHistoryController';
import { GoalSummaryView } from '@views';

interface GoalTrackerCardProps {
  groupStartDate: string | DateTime;
  cohortUserId: number;
}

export type GoalCompletionStatus = {
  [goalId: number]: GoalStatus[];
};

export const GoalTrackerCard2: React.FC<GoalTrackerCardProps> = ({ groupStartDate, cohortUserId }) => {
  const uiState = useUiState(false);
  const styles = {
    card: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid',
      borderColor: uiState.borderColor,
      borderRadius: 4,
    } as SxProps,
  };

  const { data: view, isLoading, refetch } = useGoalCardViewCreator(cohortUserId);
  const { isFirstWeek } = useIsFirstWeek(ToDate(groupStartDate));
  const jailCardController = useJailCardModalController(view);
  const confirmGoalDialogController = useConfirmGoalCompletionDialogController(cohortUserId, refetch);
  const updateGoalsDialogController = useUpdateGoalsDialogController(cohortUserId, refetch);
  const exerciseHistoryController = useExerciseHistoryController(cohortUserId);
  const enabled = true;
  const disabledMessage = 'You have not set a goal yet.';
  const [newGoalEnabled, setNewGoalEnabled] = React.useState<boolean>(false);
  const [goals, setGoals] = React.useState<{
    goals: GoalSummaryView[];
    progress: GoalCompletionStatus;
  }>({
    goals: [],
    progress: {},
  });

  useEffect(() => {
    if (!isLoading && view) {
      setGoals(view.goalsWithProgress);
    }
  }, [isLoading, view]);

  useEffect(() => {
    // If this is not week 1
    // And goals exists
    // And the user has no active goals
    // Then enable the new goal button
    if (view) {
      setNewGoalEnabled(
        !isFirstWeek && (view.goalsWithProgress?.goals || []) && (view.goalsWithProgress?.goals?.length || 0) === 0
      );
    }
  }, [isFirstWeek, view]);

  return (
    <>
      <Card variant={'elevation'} sx={styles.card}>
        <CardContent>
          <Typography variant="h5" component="div" color={enabled ? 'primary' : 'textDisabled'}>
            My Exercise Goal
          </Typography>
          {!enabled && (
            <DisabledCardState disabledMessage={disabledMessage} src="./assets/svg/calendar.svg" alt="Get stoked!" />
          )}
          {isLoading && (
            <ul>
              <li>
                <Typography color={uiState.color} variant={'h6'} sx={{ fontWeight: 300 }}>
                  <Skeleton width={'80%'}></Skeleton>
                </Typography>
              </li>
            </ul>
          )}
          {!isLoading && (
            <Grid container display={'flex'} flexDirection={'column'}>
              {isFirstWeek && enabled && (
                <Typography
                  color={'primary'}
                  sx={{
                    marginTop: '10%',
                    textAlign: 'center',
                  }}
                  variant="h5"
                  component="p"
                  gutterBottom
                >
                  Don&apos;t worry! We won&apos;t start tracking goals until next week.
                </Typography>
              )}
              {enabled &&
                goals.goals?.map((x, idx) => (
                  <GoalGrid
                    key={x.id}
                    goal={{ goal: x, progress: goals.progress[x.id] }}
                    goalCompletionStatus={goals.progress}
                    handleToggleActivityStatus={confirmGoalDialogController.openConfirmDialog}
                    enabled={enabled}
                  ></GoalGrid>
                ))}
            </Grid>
          )}
          `
        </CardContent>
        {enabled && (
          <CardActions
            sx={{
              alignItems: 'flex-end',
              justifyContent: 'flex-end',
              flexGrow: 1,
            }}
          >
            <Button onClick={() => jailCardController.setJailCardOpen(true)}>Show Available Jail Cards</Button>
            <Button
              disabled={!enabled}
              variant={'contained'}
              onClick={() => exerciseHistoryController.setHistoryDialogOpen(true)}
            >
              View Recent Exercise
            </Button>
            {newGoalEnabled && (
              <Button
                disabled={!newGoalEnabled}
                variant={'contained'}
                onClick={updateGoalsDialogController.handleShowNewGoalDialog}
              >
                Update Goals
              </Button>
            )}
          </CardActions>
        )}
      </Card>

      <ExerciseHistory
        open={exerciseHistoryController.historyDialogOpen}
        onClose={() => exerciseHistoryController.setHistoryDialogOpen(false)}
        view={exerciseHistoryController.activityHistoryView}
      ></ExerciseHistory>

      <JailCardStatusDialog
        jailCards={jailCardController.jailCards}
        onClose={() => jailCardController.setJailCardOpen(false)}
        open={jailCardController.jailCardOpen}
      ></JailCardStatusDialog>

      <ConfirmGoalCompletionDialog
        open={confirmGoalDialogController.confirmGoalDialogOpen}
        onClose={confirmGoalDialogController.cancelConfirmDialog}
        selectedGoal={confirmGoalDialogController.selectedGoal!}
        addActivity={confirmGoalDialogController.addActivity}
      ></ConfirmGoalCompletionDialog>

      <UpdateGoalsDialog
        cohortUserId={cohortUserId}
        showNewGoalDialog={updateGoalsDialogController.showNewGoalDialog}
        submitGoals={updateGoalsDialogController.submitGoals}
      ></UpdateGoalsDialog>
    </>
  );
};

interface GoalGridProps {
  goal: GoalWithStatus;
  enabled: boolean;
  goalCompletionStatus: GoalCompletionStatus;
  handleToggleActivityStatus: (goalDetails: GoalWithStatus, idx: number) => void;
}

const GoalGrid: React.FC<GoalGridProps> = ({ goal, handleToggleActivityStatus, enabled, goalCompletionStatus }) => {
  const uiState = useUiState(enabled);
  const styles = {
    toggleButton: {
      border: 0,
      borderRadius: 30,
      padding: 0.25,
      margin: 0.5,
    } as SxProps,
  };
  return (
    <Grid key={goal.goal.id} sx={{ marginTop: '10px' }}>
      <Typography>{goal.goal.goalText}</Typography>

      {[...Array(goal.goal.frequency)].map((_, idx) =>
        // Only render toggle buttons for goals that have started
        {
          return ToDate(goal.goal.startDate) <= ToDate(DateTime.now()) ? (
            <ToggleButton
              disabled={!enabled}
              color={'primary'}
              onClick={() => handleToggleActivityStatus(goal, idx)}
              selected={goalCompletionStatus[goal.goal.id]?.includes(idx)}
              key={idx}
              sx={styles.toggleButton}
              value={`${goal.goal.id}-${idx}`}
            >
              {goalCompletionStatus[goal.goal.id]?.[idx] === GoalStatus.Succeeded ? (
                <Circle color={uiState.buttonColor} />
              ) : (
                <CircleOutlined color={uiState.buttonColor} />
              )}
            </ToggleButton>
          ) : null;
        }
      )}
    </Grid>
  );
};
