import { AsyncEmptyFunction, EmptyFunction } from '@utils/common-types';
import React from 'react';
import { DateTime } from 'luxon';
import { RecordActivityView } from '@views';
import { useAddActivityAction } from '@actions';
import { GoalStatus } from '@app/enums/GoalStatus';
import { GoalWithStatus } from '@components';

export const useConfirmGoalCompletionDialogController = (cohortUserId: number, refresh: EmptyFunction) => {
  const [confirmGoalDialogOpen, setConfirmGoalDialogOpen] = React.useState(false);
  // const [goalCompletionStatus, setGoalCompletionStatus] = React.useState<GoalCompletionStatus>({});
  const [selectedGoal, setSelectedGoal] = React.useState<GoalWithStatus>();
  const [alertOpen, setAlertOpen] = React.useState(false);

  const { mutateAsync: addActivityAction } = useAddActivityAction();

  const addActivity: AsyncEmptyFunction = async () => {
    if (selectedGoal) {
      const item = {
        activityId: selectedGoal.goal.activity,
        cohortUserId: cohortUserId,
        dateStamp: DateTime.now(),
        duration: selectedGoal.goal.duration,
        timeframeId: selectedGoal.goal.timeframe,
        name: selectedGoal.goal.goalText,
      } as RecordActivityView;
      await addActivityAction(item);
      setSelectedGoal({ ...selectedGoal });
      setConfirmGoalDialogOpen(false);
      refresh();
    }
  };
  const cancelConfirmDialog = (selected: GoalWithStatus, idx: number) => {
    if (selected.progress[idx]) {
      selected.progress[idx] = GoalStatus.Unknown;
    }

    setSelectedGoal({ ...selected });
  };

  const openConfirmDialog = (selected: GoalWithStatus, idx: number) => {
    if (!selected.progress) {
      selected.progress = [...Array(selected.goal.frequency)].map(() => GoalStatus.Unknown);
    }
    selected.progress[idx] = GoalStatus.Succeeded;
    setSelectedGoal({ ...selected });
    setConfirmGoalDialogOpen(true);
  };

  return {
    confirmGoalDialogOpen,
    cancelConfirmDialog,
    openConfirmDialog,
    selectedGoal,
    setSelectedGoal,
    alertOpen,
    setAlertOpen,
    addActivity,
  };
};
