import { useState } from 'react';
import { RawGoal } from '@views';
import { EmptyFunction } from '@utils/common-types';
import { useCreateGoalAction } from '@actions/CreateGoalAction';

export const useUpdateGoalsDialogController = (cohortUserId: number, refresh: EmptyFunction) => {
  const [showNewGoalDialog, setShowNewGoalDialog] = useState<boolean>(false);
  const [rawGoals, setRawGoals] = useState<RawGoal[]>([]);

  const { mutateAsync: createGoals } = useCreateGoalAction();
  const saveGoals = async (e: any, reason: 'SAVE' | 'CANCEL', goals: RawGoal[]) => {
    await createGoals(goals);
    setShowNewGoalDialog(false);
    refresh();
  };

  return {
    handleShowNewGoalDialog: () => setShowNewGoalDialog(true),
    showNewGoalDialog,
    submitGoals: saveGoals,
    rawGoals,
    setRawGoals,
  };
};
