import { useState } from 'react';
import { useActivityHistoryViewCreator } from '@viewCreators';
import { ActivityHistoryView } from '@views';

export const useExerciseHistoryController = (cohortUserId: number) => {
  const [historyDialogOpen, setHistoryDialogOpen] = useState<boolean>(false);
  const { data: activityHistoryView, refetch } = useActivityHistoryViewCreator(cohortUserId);
  if (!activityHistoryView) {
    return {
      historyDialogOpen,
      setHistoryDialogOpen,
      activityHistoryView: { items: [] } as ActivityHistoryView,
    };
  }
  return {
    historyDialogOpen,
    setHistoryDialogOpen,
    activityHistoryView,
  };
};
