import React, { useEffect } from 'react';
import { DateTime } from 'luxon';

export const useIsFirstWeek = (groupStartDate: DateTime) => {
  const [isFirstWeek, setIsFirstWeek] = React.useState<boolean>(false);

  const calculateWeek1 = (): boolean => {
    const now = DateTime.now();
    const startDate = groupStartDate;
    // If startDate is not a DateTime, we don't have the proper value from the back end yet
    if (!startDate || !(startDate instanceof DateTime)) {
      return false;
    }

    // Calculate the number of days between the start date and now
    const days = now.diff(startDate, 'days').days;
    return days >= 0 && days < 7;
  };

  useEffect(() => {
    setIsFirstWeek(calculateWeek1());
  }, [groupStartDate]);

  return { isFirstWeek };
};
