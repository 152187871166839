import { GoalsWithStatus } from '@components';
import { GoalStore } from '@stores';
import { useQuery } from '@tanstack/react-query';
import { Duration } from 'luxon';

export interface GoalCardView {
  goalsWithProgress: GoalsWithStatus;
  jailCards: number;
}

export class GoalCardViewCreator {
  constructor(private readonly goalStore: GoalStore = new GoalStore()) {}

  public async CreateView(cohortUserId: number): Promise<GoalCardView> {
    // Get the content from the API
    const content = await this.goalStore.ReadByCohortUserId(cohortUserId);
    const goals = content.result.goals;
    const progress = content.result.progress;
    const goalsWithProgress = {
      goals: goals,
      progress: progress,
    };
    const jailCards = content.result.participantJailCards;

    // Return the view
    return {
      goalsWithProgress: goalsWithProgress,
      jailCards: jailCards,
    };
  }
}

export const useGoalCardViewCreator = (cohortUserId: number) =>
  useQuery({
    queryKey: ['goalCardViewCreator', cohortUserId],
    queryFn: () => new GoalCardViewCreator().CreateView(cohortUserId),
    staleTime: Duration.fromObject({ minutes: 1 }).milliseconds,
  });
