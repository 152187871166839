import { Typography } from '@mui/material';
import React from 'react';

interface DisabledCardStateProps {
  disabledMessage: string;
  src: string;
  alt: string;
}

const DisabledCardState: React.FC<DisabledCardStateProps> = ({ disabledMessage, src, alt }) => {
  return (
    <div className="card-content-wrapper disabled">
      <Typography
        sx={{
          textAlign: 'center',
          marginTop: '20px',
        }}
        variant="h5"
        component="p"
        gutterBottom
      >
        {disabledMessage}
      </Typography>
      <img src={src} alt={alt} className="card-logo" />
    </div>
  );
};

export default DisabledCardState;
