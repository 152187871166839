import { DebugPanel } from '@components';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { EmptyFunction } from '@utils/common-types';

export interface JailCardStatusDialogProps {
  open: boolean;
  onClose: EmptyFunction;
  jailCards: number;
}

export const JailCardStatusDialog: React.FC<JailCardStatusDialogProps> = ({ open, jailCards, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DebugPanel displayItem={jailCards}></DebugPanel>
      <DialogTitle>Available Get Out Of Jail Cards</DialogTitle>
      <DialogContent>
        <Typography component={'div'}>
          {'Available Get Out of Jail Free Cards:'}
          <Typography sx={{ paddingLeft: 2 }} component={'span'} variant={'h4'}>
            {jailCards}
          </Typography>
        </Typography>
        <List>
          {Array.from({ length: jailCards }, (_, index) => (
            <ListItem key={index}>
              <img
                src={
                  'https://images.ctfassets.net/ihnuhnmumsts/SvOPfL2qrjRB68cpu5e96/3ff57b3375721b425c6ce8eade8dfcbf/B34EE134-411A-4A51-98B2-D45705EAEA72.png?fm=webp&q=75'
                }
                alt={'Jail'}
                width="200"
              ></img>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Dismiss</Button>
      </DialogActions>
    </Dialog>
  );
};
