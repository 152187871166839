import { DashboardView } from '@views';
import { Container, Skeleton, Typography } from '@mui/material';
import React from 'react';

export const DashboardHeader = (props: { loading: boolean; view: DashboardView }) => {
  return (
    <Container sx={{ padding: 2 }}>
      {props.loading && (
        <Typography variant={'h3'}>
          <Skeleton width="80%" />
        </Typography>
      )}
      {!props.loading && <Typography variant={'cursive'}>{props.view.groupName}</Typography>}
    </Container>
  );
};
