import { GoalCardView } from '@viewCreators/GoalCardViewCreator';
import React from 'react';

export const useJailCardModalController = (view: GoalCardView | undefined) => {
  const [jailCardOpen, setJailCardOpen] = React.useState(false);
  if (!view) {
    return {
      jailCards: 0,
      jailCardOpen,
      setJailCardOpen,
    };
  }
  return {
    jailCards: view?.jailCards,
    jailCardOpen,
    setJailCardOpen,
  };
};
